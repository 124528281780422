export default {
  beforeMount() {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const {
      email,
      user_id: userId,
      f_name: fName,
      l_name: lName,
      is_gis_admin: isGisAdmin,
      is_locate_request_user: isLocatePowerUser,
      is_power_user: isPowerUser,
      is_sys_admin: isSysAdmin,
      is_view_only_user: isViewOnlyUser,
      username,
    } = auth ?? {};
    window.usetifulTags = {
      email,
      userId: userId,
      f_name: fName,
      l_name: lName,
      is_gis_admin: JSON.stringify(isGisAdmin),
      is_locate_request_user: JSON.stringify(isLocatePowerUser),
      is_power_user: JSON.stringify(isPowerUser),
      is_sys_admin: JSON.stringify(isSysAdmin),
      is_view_only_user: JSON.stringify(isViewOnlyUser),
      username,
    };
    (function (w, d, s) {
      var a = d.getElementsByTagName("head")[0];
      var r = d.createElement("script");
      r.async = 1;
      r.src = s;
      r.setAttribute("id", "usetifulScript");
      r.dataset.token = "4fd9a02f6e5b519a885815a6eea2c10b";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");
  },
  beforeDestroy() {
    const s = document.getElementById("usetifulScript");
    s.remove();
  },
};
